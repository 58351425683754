$(function init() {
    window.catalogModels = catalogModels;
});

class catalogModels {
    /**
     @property {Object} response
     @property {Array} response.data
     @property {Array} response.fieldsFilter
     @property {Number} response.recordsFiltered
     @property {Number} response.length
     @property {Number} response.page
     @property {Boolean} response.isShowLastPageButton
     */
    constructor(response) {
        this.drawModels(response);
        this.drawPagination(response);
    }

    drawModels(response) {
        let data = response.data;
        if (data.length) {
            let html = '';

            for (let i = 0; i < data.length; i++) {
                /**
                 * @property {Object} model
                 * @property {Number} model.id
                 * @property {Number} model.price
                 * @property {Number} model.priceMax
                 * @property {Number} model.actualPrice
                 * @property {Number} model.actualPriceMax
                 * @property {Number} model.retail
                 * @property {Number} model.retailMax
                 * @property {String} model.group_category
                 * @property {Array} model.coverByColorCode
                 * @property {String} model.typeCode
                 * @property {String} model.modelCode
                 * @property {String} model.groupCode
                 * @property {String} model.model
                 * @property {String} model.sku
                 * @property {String} model.group_category
                 * @property {String} model.sCurrency
                 * @property {String} model.color
                 * @property {Boolean} model.isOutlet
                 * @property {Boolean} model.isNew
                 * @property {Boolean} model.isPreOrder
                 * @property {Boolean} model.isHonestSign
                 * @property {Number} model.discount
                 * @property {Number} model.discountMax
                 * @property {Object} model.hoverPhoto
                 */
                let model = data[i],
                    badges = getModelBadges(model);

                const sizePreviewPhoto = 320;
                const modelSlug = this.getModelSlug(model);
                const coverPhoto = getCoverPhoto(model, sizePreviewPhoto, window.isSplitByColor);
                const hoverPhoto = model?.hoverPhoto['thumb_'+sizePreviewPhoto] ?? undefined;
                const mainPhotoHtml = getMainPhotoTemplate(coverPhoto.src, coverPhoto.originalSrc, hoverPhoto);
                const isHover = mainPhotoHtml.indexOf(' hover') >= 0 ? ' hover' : '';

                html += this.modelTemplate(model, i, data)
                    .replace(/\{isHover}/g, isHover)
                    .replace(/\{photos}/g, mainPhotoHtml)
                    .replace(/\{modelId}/g, model.id)
                    .replace(/\{modelSlug}/g, modelSlug)
                    .replace(/\{typeCode}/g, model.typeCode)
                    .replace(/\{modelCode}/g, model.modelCode)
                    .replace(/\{groupCode}/g, model.groupCode)
                    .replace(/\{patternCode}/g, model.patternCode || '')
                    .replace(/\{brand}/g, model.brand)
                    .replace(/\{modelName}/g, model.model)
                    .replace(/\{modelSku}/g, model.sku)
                    .replace(/\{group_category}/g, model.group_category)
                    .replace(/\{badges}/g, badges)
                    .replace(/\{priceFrom}/g, CONFIG.LANG.phraseFrom)
                    .replace(/\{priceViews}/g, this.priceTemplate(model))
                    .replace(/\{countViews}/g, this.countTemplate(model))
                    .replace(/\{priceRetailViews}/g, this.priceRetailTemplate(model))
                    .replace(/\{price}/g, this.getPrice(model.actualPrice, model.price))
                    .replace(/\{priceMax}/g, this.getPrice(model.actualPriceMax, model.priceMax))
                    .replace(/\{currency}/g, model.sCurrency)
                    .replace(/\{addButton}/g, CONFIG.LANG.buttonsChoice)
                    .replace(/\{colorName}/g, model.color)
                    .replace(/\{honestSign}/g, model.isHonestSign ? '<div class="honest_sign"></div>' : '')
                ;

                models[modelSlug] = model;
            }

            $gridModelsBox.html(html);
            $countOnPage.show();
        } else {
            if (!window.isModelsLoading) {
                this.setCatalogEmpty();
            }
        }
    }

    drawPagination(response) {
        const currentPage = response.page;
        const lastPage = Math.ceil(response.recordsFiltered / response.length);
        const countAround = 3;

        let html = '';

        if (lastPage > 1) {
            if (currentPage - countAround > 1) {
                html += this.getPageButtonHtml(1);
                if (currentPage - countAround > 2) {
                    html += this.getPageSeparatorHtml();
                }
            }

            let maxPage = 0;
            for (let i = currentPage - countAround; i < (currentPage + countAround + 1); i++) {
                if (i > 0 && i <= lastPage) {
                    html += this.getPageButtonHtml(i, i === currentPage);
                }
                maxPage = i;
            }

            if (maxPage < lastPage && lastPage !== currentPage) {
                if (lastPage - maxPage >= 2) {
                    html += this.getPageSeparatorHtml();
                }

                if (response.isShowLastPageButton !== false) {
                    html += this.getPageButtonHtml(lastPage);
                }
            }

            $gridPaginationBox.html(html);
        } else {
            $gridPaginationBox.html('');
        }
    }

    getPageButtonHtml (pageNumber, current){
        const currentParams = $.extend({}, window.getParams(), {page:pageNumber});
        const url = createUrlWithParams(currentParams);
        return `<a href="${url}"
                data-page="${pageNumber}"
                class="pagination__page-button shadow${(current?' pagination__page-button_current':'')}">${pageNumber}</a>`;
    }

    getPageSeparatorHtml (){
        return '<div class="pagination__page-separator">...</div>';
    }

    getPrice(actualPrice, price) {
        let template;
        if (actualPrice > 0 && actualPrice !== price) {
            template = '<span class="price-width-discount"><span class="price-oldValue">' + number_format(actualPrice, 2, ',', ' ') + '</span><span class="price-value">' + number_format(price, 2, ',', ' ') + '</span></span>';
        } else {
            template = '<span class="price-value">' + number_format(price, 2, ',', ' ') + '</span>';
        }
        return template;
    }

    getIsDiscount(model) {
        const isActualPrice = model.actualPrice > 0 && model.actualPrice !== model.price;
        const isActualMaxPrice = model.actualPriceMax > 0 && model.actualPriceMax !== model.priceMax;
        return isActualPrice || isActualMaxPrice ? ' price-wrap-width-discount' : '';
    }

    priceTemplate(model) {
        let priceViews = '';
        const isDiscount = this.getIsDiscount(model.actualPrice, model.price, model.actualPriceMax, model.priceMax);
        if (!CONFIG.ROLE.ROLE_RETAIL_NETWORK) {
            priceViews = `<span class="model-price-wrap"><span class="price-wrap${isDiscount}">{price}`;
            if ((model.price !== model.priceMax || model.actualPrice !== model.actualPriceMax) && model.priceMax > 0) {
                priceViews += ' - {priceMax}';
            }
            priceViews += ` <span class="currency-wrapper">{currency}</span></span> <span class="currency-wrapper">${CONFIG.LANG.columnWholesalePrice}</span></span>`;
        }
        return priceViews;
    }

    priceRetailTemplate(model) {
        let priceRetailViews = '';
        if (model.retail > 0 || model.retailMax > 0) {
            priceRetailViews = '<div class="price-retail-wrap">';
            if ((model.retail !== model.retailMax) && model.retailMax > 0) {
                priceRetailViews += '<span class="price-wrap"><span class="price-value">' + number_format(model.retail, 2, ',', ' ') + '</span> - <span class="price-value">' + number_format(model.retailMax, 2, ',', ' ') + '</span> {currency}</span>';
            } else {
                priceRetailViews += '<span class="price-wrap"><span class="price-value">' + number_format(model.retail, 2, ',', ' ') + '</span> {currency}</span>';
            }
            priceRetailViews += `<span class="price-retail-label">${CONFIG.LANG.columnRetailPriceModel}</span></div>`
        }
        return priceRetailViews;
    }

    countTemplate(model) {
        let availableCount = model.availableCount <= 0 ? 0 : model.availableCount;
        let delayCount = model.delayCount <= 0 ? 0 : model.delayCount;
        availableCount = availableCount <= 100 || CONFIG.DATA.showActualBalance ? availableCount : '100+';
        delayCount = delayCount <= 100 || CONFIG.DATA.showActualBalance ? delayCount : '100+';
        const availableCountZeroCalss = availableCount <= 0 ? ' count-zero' : '';
        const delayCountZeroCalss = delayCount <= 0 ? ' count-zero' : '';
        let countViews = '<div class="count-wrap">' +
            '<span class="count-available' + availableCountZeroCalss + '">' +
            '<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path d="M9.5 0.25C7.9087 0.25 6.38258 0.882141 5.25736 2.00736C4.13214 3.13258 3.5 4.6587 3.5 6.25C3.5 7.8413 4.13214 9.36742 5.25736 10.4926C6.38258 11.6179 7.9087 12.25 9.5 12.25C11.0913 12.25 12.6174 11.6179 13.7426 10.4926C14.8679 9.36742 15.5 7.8413 15.5 6.25C15.5 4.6587 14.8679 3.13258 13.7426 2.00736C12.6174 0.882141 11.0913 0.25 9.5 0.25ZM2.6975 3.115C1.31 4.255 0.5 5.9575 0.5 7.75C0.5 9.3413 1.13214 10.8674 2.25736 11.9926C3.38258 13.1179 4.9087 13.75 6.5 13.75C6.98 13.75 7.4525 13.69 7.91 13.5775C6.59 13.285 5.375 12.625 4.3775 11.7175C2.915 10.9375 2 9.4075 2 7.75C2 7.525 2.0225 7.3075 2.0525 7.0825C2.0225 6.805 2 6.5275 2 6.25C2 5.17 2.24 4.0975 2.6975 3.115ZM12.5675 3.31L13.625 4.375L8.75 9.25L5.9075 6.4075L6.9725 5.3425L8.75 7.1275" fill="#1CA858"/>' +
            '</svg>' +
            availableCount +
            '</span>' +
            '<span class="count-delay' + delayCountZeroCalss + '">' +
            '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path d="M6.99999 0.532227C3.44272 0.532227 0.532227 3.44272 0.532227 6.99999C0.532227 10.5573 3.44272 13.4677 6.99999 13.4677C10.5573 13.4677 13.4677 10.5573 13.4677 6.99999C13.4677 3.44272 10.5573 0.532227 6.99999 0.532227ZM9.47662 9.51552L5.92203 7.53897V3.76611H7.32337V6.61192L10.2339 8.22886L9.47662 9.51552Z" fill="#1E526A"/>' +
            '</svg>' +
            delayCount +
            '</span>';

        countViews += '</div>';
        return countViews;
    }

    modelTemplate(model, i, data) {
        let modelHtml = '';

        const modelSlug = this.getModelSlug(model);
        const addClassAccess = CONFIG.DATA.isNewPage && model.isAccess ? ' hiddenPrice' : '';
        const wrapTagName = CONFIG.DATA.userId ? 'div' : 'a';
        const linkModel = CONFIG.DATA.userId ? '' : ' href="' + createUrlWithParams({
            modelSlug: modelSlug,
            categorySlug: model.categorySlug
        }) + '"';

        const isCatalogPage = !((CONFIG.DATA.isNewPage && CONFIG.DATA.isOutletPage) || CONFIG.DATA.isSearchPage);
        const isChangeGroupCategory = i === 0 || model.group_category !== data[i - 1].group_category;
        if (isCatalogPage && isChangeGroupCategory) {
            modelHtml += '<div class="category-separator"><h2>{group_category}</h2></div>'
        }

        modelHtml += '<' + wrapTagName + linkModel + ' class="model-card' + addClassAccess + '" ' +
            'data-model-id="{modelId}" ' +
            'data-model-slug="{modelSlug}" ' +
            'data-model-typecode="{typeCode}" ' +
            'data-model-modelcode="{modelCode}" ' +
            'data-model-groupcode="{groupCode}" ' +
            'data-model-patterncode="{patternCode}" ' +
            'data-model-name="{modelName}"' +
            (window.isSplitByColor ? ' data-model-colorname = "{colorName}"' : '') +
            '>' +
            '{badges}' +
            '<div>' +
            '<div class="model-photo-wrapper{isHover}">' +
            '{photos}' +
            (window.isSplitByColor && model.coverByColorCode.length === 0 && model.color ? '<div class="color-name" title="{colorName}">{colorName}</div>' : '') +
            '</div>' +
            '<div class="model-name" title="{modelName}">{modelName}</div>' +
            '<div class="model-pattern">{patternCode}</div>' +
            '<div class="model-sku">{modelSku}</div>' +
            '</div><div>' +
            '<div class=model-retail-price>{priceRetailViews}</div>' +
            '<div class="model-price">{priceViews}</div>' +
            '<div class="model-count">{countViews}{honestSign}</div>' +
            '</div>' +
            (CONFIG.ROLE.ROLE_ADMIN ? '<div class="model-close-button js-model-close">×</div>' : '') +
            '<div class="loading"></div>' +
            '</' + wrapTagName + '>';
        return modelHtml;
    }

    getModelSlug(model) {
        let modelSlug = model.slug;
        if (modelSlug === '') {
            modelSlug = model.id
        }
        return modelSlug;
    }

    setCatalogEmpty() {
        $gridModelsBox.html(`<div class="empty">${CONFIG.LANG.catalogEmpty}</div>`);
        $countOnPage.hide();
    }
}

window.getModelBadges = function (model) {
    let badges = '';
    if (model.isOutlet) {
        badges += `<div class="product-badges outlet">${CONFIG.LANG.badgesOutlet}</div>`;
    }
    if (model.availableCount < 1 && model.delayCount > 0) {
        badges += `<div class="product-badges in-order">${CONFIG.LANG.badgesInOrder}</div>`;
    }
    if (model.isNew) {
        badges += `<div class="product-badges new">${CONFIG.LANG.badgesNew}</div>`;
    }
    if (model.isPreOrder) {
        badges += `<div class="product-badges pre-order">${CONFIG.LANG.badgesPreOrder}</div>`;
    }

    if (
        !CONFIG.ROLE.ROLE_RETAIL_NETWORK &&
        (model.discount > 0 || model.discountMax > 0)
    ) {
        const discountText = model.discount > model.discountMax ? model.discount : model.discountMax,
            text = (discountText ? '-' + discountText : '') + '%',
            addClass = discountText ? ' with-percents' : '';
        badges += '<div class="product-badges sale' + addClass + '">' + text + '</div>';
    }

    return badges;
};
